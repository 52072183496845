import React, { useState } from 'react';
import './AdminLogin.css';

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async () => {
        try {
            const res = await fetch('/api/admin/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            if (!res.ok) {
                throw new Error('Invalid credentials');
            }

            const data = await res.json();
            localStorage.setItem('adminToken', data.token); // Save token if needed
            window.location.href = '/admin/dashboard'; // Redirect to dashboard
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="admin-login-container">
            <div className="admin-login-content">
                <h1 className="admin-login-title">Admin Login</h1>
                <form className="admin-login-form" onSubmit={(e) => e.preventDefault()}>
                    <div className="admin-form-group">
                        <label className="admin-label">Username</label>
                        <input
                            type="text"
                            className="admin-input-field"
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="admin-form-group">
                        <label className="admin-label">Password</label>
                        <input
                            type="password"
                            className="admin-input-field"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button
                        type="button"
                        className="admin-login-button"
                        onClick={handleLogin}
                    >
                        Login
                    </button>
                </form>
                {error && <p className="admin-error-message">{error}</p>}
            </div>
        </div>
    );
};

export default AdminLogin;
