// index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client' in React 18
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';


// Create root element for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <AuthProvider> 
            
                <App />
            
        </AuthProvider>
    </Router>
);