import React, { useState, useContext,useEffect,useRef } from 'react';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed
import { apiRequest } from '../api';
import { QrReader } from 'react-qr-reader';
import './Withdraw.css'; 
import API_BASE_URL from '../apiConfig';



const Withdraw = ({ balance, setBalance }) => {
    const { user } = useContext(AuthContext); 
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState(user?.savedAddress || '');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(address ? 2 : 1); 
    const [isAddressValid, setIsAddressValid] = useState(true);
    const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);
    const [attachedAddress, setAttachedAddress] = useState(address); 
    const [showAddressPopup, setShowAddressPopup] = useState(false); 
    const networkFee = 1.5; 
    const restrictedAmount = 0;
    const withdrawalAmount = Math.max(0, amount - networkFee);
    const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);

    useEffect(() => {
    
        if (user && user.savedAddress) {
            setAddress(user.savedAddress);
            setStep(2);
        }
    }, [user]);

    useEffect(() => {
        if (user?.balance) {
            setMaxWithdrawalAmount(Math.max(0, balance - restrictedAmount)); 
        }
    }, [balance]);

  
  
    const validateAddress = () => {
        return address.startsWith('T') && address.length === 34;
    };

    const handleMaxAmount = () => {
        const roundedMaxWithdrawalAmount = Math.floor(maxWithdrawalAmount * 100) / 100; 
        if (roundedMaxWithdrawalAmount > 0) {
            setAmount(roundedMaxWithdrawalAmount.toFixed(2)); 
        }
        
    };
    

  

    const handleDisconnect = async () => {
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/wallet/remove-address`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                setAttachedAddress('');
                setAddress('');
                setStep(1); 
                setShowAddressPopup(false); 
                setMessage('Address successfully removed');
            } else {
                setMessage('Failed to remove the address.');
            }
        } catch (err) {
            setMessage('Error removing the address.');
        }
    };
    
    

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setAddress(text);
        } catch (err) {
            console.error('Clipboard read operation is not allowed', err);
            }
    };

   
    const handleScan = (data) => {
        if (data) {
            console.log("QR Data:", data);
            setAddress(data); // directly use data without data.text
            setIsQRScannerOpen(false); 
        } else {
            console.log("No data detected"); // helps debug if onScan is called
        }
    };

    const handleResult = (result, error) => {
    if (result) {
        setAddress(result.text);
        setIsQRScannerOpen(false);
    }
    
};
    
    const handleError = (err) => {
       // Do nothing if there's an error, effectively silencing it
    };

    const escapeHTML = (input) => {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;',
        };
        return input.replace(/[&<>"']/g, (char) => map[char]);
    };
    

    const handleContinue = async () => {
        if (!validateAddress()) {
            setMessage('Please enter a valid TRC20 wallet address.');
        } else {
            setMessage('');

          
        const sanitizedAddress = escapeHTML(address);
            

            try {
                 const response = await apiRequest(`${API_BASE_URL}/api/wallet/save-address`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ savedAddress: sanitizedAddress  }) 
                });
    
                if (response.ok) {

                    setAttachedAddress(address);
                    user.savedAddress = address; 
                    console.log('Updated user:', user);
                    setMessage('Address successfully attached!');
                    setStep(2); 
                } else {
                    setMessage('Failed to save the address.');
                }
            } catch (err) {
                setMessage('Error saving the address.');
            }
        }
    };
    

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage("");
            }, 5000); 
            return () => clearTimeout(timer); 
        }
    }, [message]);

    
    const [isProcessing, setIsProcessing] = useState(false);


    const handleWithdraw = async () => {
       
        

        if (!user?._id || !amount || !address) {
            setMessage('User ID, amount, and address are required');
            return;
        }

        if (balance < amount) {
            setMessage('Failed to withdraw: Insufficient balance');
            return;
        }

        if (isProcessing) return;
        setIsProcessing(true); 


        try {
            const response = await apiRequest(`${API_BASE_URL}/api/withdraw`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user._id, amount})
            });

            const data = await response.json();

            if (response.ok) {
                setBalance(data.newBalance); 
                setAmount('');
                setMessage('Withdrawal successful');
                
            } else {
            
                setMessage(`Failed to withdraw: ${data.error}`);
            }
        } catch (err) {
         
            setMessage('Error processing withdrawal');
        }finally {
            setIsProcessing(false); 
        }
    };


    return (
        <div className="withdraw-container">
    <h2 className="withdraw-title">Withdraw</h2>

    


    {step === 1 && (
        <div className="step-one">
            <h3 className="step-title">Enter your USDT TRC20 address</h3>
            <p className="warning-text">Make sure your address is correct to avoid losing funds.</p> 
            <div className="address-input-container">
                <input
                    type="text"
                    placeholder="TRC20 Wallet Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className={`address-input ${!isAddressValid ? 'invalid' : ''}`}
                />
                <div className="action-buttons">
            <button onClick={handlePaste} className="paste-button">Paste</button>
            <button onClick={() => setIsQRScannerOpen(true)} className="qr-scan-button">
                <img src="/icons/qr-scan-icon.svg" alt="Scan QR" className="qr-scan-icon" />
            </button>
        </div>
            </div>

            {isQRScannerOpen && (
    <div className="qr-scanner-overlay">
        <div className="qr-scanner-popup-unique">
            
           
            <h3 className="qr-scanner-title">Scan QR Code</h3>
            <p className="qr-scanner-subtitle">Scan your USDT TRC-20 address</p>
            
            <div className="qr-scanner-frame">
            <QrReader
    delay={300}
    onError={handleError}
    onResult={handleResult} 
    style={{ width: '100%' }}
    constraints={{ facingMode: { exact: "environment" } }} // For back camera
/>
            </div>
            
            <button className="unique-close-button" onClick={() => setIsQRScannerOpen(false)}>
                &times;
            </button>
        </div>
    </div>
)}



{message && (
    <p className={message.includes('successfully') ? 'success-message' : 'error-message'}>
        {message}
    </p>
)}

<div className="button-container">
    <button onClick={handleContinue} className="continue-button">Continue</button>
</div>


        </div>
    )}

{step === 2 && (
    <div className="step-two">
       <div className="attached-address-container"onClick={() => setShowAddressPopup(true)}>
    <h3 className="attached-address-titul">Withdrawal Address:</h3>


    <div className="attached-address" >
        <p className="attached-address-text">{attachedAddress}</p>
        <img src="/icons/arrow-icon.svg" alt="Arrow Icon" className="arrow-icon" />
    </div>
</div>



        {showAddressPopup && (
            <div className="address-popup-overlay" onClick={() => setShowAddressPopup(false)}>
                <div className="address-popup">
                    <h4>{attachedAddress}</h4>
                    <button className="disconnect-button" onClick={handleDisconnect}>Disconnect</button>
                    <button className="disconnect-close-popup" onClick={() => setShowAddressPopup(false)}>&times;</button>
                </div>
            </div>
        )}
        
        <h3 className="amount-title">Enter Amount to Withdraw:</h3>
        <div className="amount-input-container">
    <input
        type="number"
        placeholder="Amount in USDT"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        className="amount-input"
        onWheel={(e) => e.target.blur()} 
    />
      
      <button className="max-button" onClick={handleMaxAmount}>
    Max: {Math.floor(maxWithdrawalAmount * 100) / 100} 
</button>

</div>


{amount && amount <= networkFee && (
    <p className="withdrawal-warning"> Amount should be greater than {networkFee} USDT.</p>
)}

{amount > networkFee && (
    <div className="fee-info">
        <p className="fee-details">
            Network Fee: {networkFee} USDT
        </p>
        <p className="fee-details">
            Final Withdrawal Amount: <strong>{withdrawalAmount.toFixed(2)} USDT</strong>
        </p>
    </div>
)}
        <div className="info-section">
        <p>Withdrawals typically take 10-30 minutes, depending on the network congestion.</p>
            </div>

            {message && (
    <p className={message.includes('successfully') ? 'success-message' : 'error-message'}>
        {message}
    </p>
)}
            
        <button 
    onClick={handleWithdraw} 
    className={`withdraw-button ${isProcessing ? 'withdraw-disabled' : ''}`} 
    disabled={isProcessing || amount <= networkFee} 
>
    {isProcessing ? (
        <>
            <span className="spinner"> </span>   Processing...  
        </>
    ) : "Request Withdrawal"} 
</button>

        

    </div>
)}



    
</div>





    );

    
};

export default Withdraw;
