import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../api';
import { AuthContext } from '../context/AuthContext';
import API_BASE_URL from '../apiConfig';
import './Friends.css';

const FriendsPage = ({ balance, setBalance }) => {
    const { user, socket } = useContext(AuthContext);
    const [friends, setFriends] = useState([]);
    const [totalRewards, setTotalRewards] = useState(0); // Tracks the total rewards
    const [isClaiming, setIsClaiming] = useState(false);
    const [claimSuccessPopup, setClaimSuccessPopup] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const scrollableContent = document.getElementById('scrollableContent');

        if (claimSuccessPopup.isVisible) {
            // Lock scroll and calculate current scroll position
            scrollableContent.style.overflow = 'hidden';
            const currentScroll = scrollableContent.scrollTop;
            setScrollPosition(currentScroll); // Store scroll position
        } else {
            // Unlock scroll
            scrollableContent.style.overflow = 'auto';
        }

        return () => {
            scrollableContent.style.overflow = 'auto';
        };
    }, [claimSuccessPopup.isVisible]);

    // Fetch friends data and total rewards
    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const response = await apiRequest(`${API_BASE_URL}/api/friends` , {
                    method: 'GET',
                    credentials: 'include',
                }); 
                const data = await response.json();
                if (response.ok) {
                    setFriends(data.friends);
                    setTotalRewards(data.totalRewards); // Sum of claimable rewards from all friends
                }
            } catch (error) {
                console.error('Error fetching friends:', error);
            }
        };
        fetchFriends();
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('friendAdded', (newFriend) => {
                console.log('New friend added:', newFriend);

                // Update friends list dynamically
                setFriends((prevFriends) => [...prevFriends, newFriend]);
            });

            return () => {
                socket.off('friendAdded'); // Cleanup listener on unmount
            };
        }
    }, [socket]);
   
  


    // Prevent the back button from navigating away from this page
    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate('/game'); // Navigate to game page when back button is pressed
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    // Handle claiming rewards
    const handleClaimRewards = async () => {
        setIsClaiming(true);  // Set the loading state
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/claim-rewards` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });  // Ensure refreshTokenFn is included for token handling
    
            const data = await response.json();
            if (response.ok) {

                triggerConfettiAnimation();

                const claimedAmount = data.totalRewards;
                setTotalRewards(0);  // Reset the total rewards display
                setBalance(data.newBalance);  // Update the user's balance in the UI
                setFriends(prevFriends => prevFriends.map(friend => ({ ...friend, earnings: 0 })));  // Reset earnings for each friend
    
                 // Show a success popup with the claimed amount
            setClaimSuccessPopup({
                isVisible: true,
                title: 'Rewards Collected!',
                message: `You’ve successfully claimed ${claimedAmount.toFixed(2)} USDT! It’s added to your wallet.`,
            });
            
            
    
            } else {
                console.error('Failed to claim rewards:', data.message);
            }
        } catch (error) {
            console.error('Error claiming rewards:', error);
        } finally {
            setIsClaiming(false);  // Reset loading state
            // Trigger confetti animation
            
        }
    };

    const triggerConfettiAnimation = () => {
        const confettiContainer = document.createElement('div');
        confettiContainer.className = 'confetti-container';
    
        for (let i = 0; i < 200; i++) { // Increased the number of confetti pieces
            const confetti = document.createElement('div');
            confetti.className = 'confetti-piece';
            
            // Set random styles for confetti
            confetti.style.left = '50%'; // Start at the center horizontally
            confetti.style.top = '50%';  // Start at the center vertically
            confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`; // Random color
            confetti.style.transform = `rotate(${Math.random() * 360}deg)`; // Random rotation
    
            // Random direction and larger distance for full-screen explosion effect
            const randomX = (Math.random() - 0.5) * window.innerWidth * 2; // Horizontal distance (now up to 2x screen width)
            const randomY = (Math.random() - 0.5) * window.innerHeight * 2; // Vertical distance (now up to 2x screen height)
            const duration = Math.random() * 1.4 + 0.8; // Faster animation duration (between 0.6 to 1.4 seconds)
    
            confetti.style.animation = `explode ${duration}s ease-out forwards`;
            confetti.style.setProperty('--randomX', `${randomX}px`); // Custom property for horizontal movement
            confetti.style.setProperty('--randomY', `${randomY}px`); // Custom property for vertical movement
    
            confettiContainer.appendChild(confetti);
        }
    
        document.body.appendChild(confettiContainer);
    
        setTimeout(() => {
            document.body.removeChild(confettiContainer);
        }, 4000);  // Confetti lasts for 2 seconds
    };
    
    
       

    return (
        <div className="friends-page-container">

{claimSuccessPopup.isVisible && (
    <div
        className="claimFriends-reward-popup-overlay"
        style={{ top: `${scrollPosition}px` }}
        onClick={(e) => {
            if (!e.target.closest('.claim-reward-popup')) {
                setClaimSuccessPopup({ isVisible: false });
            }
        }}
    >
        <div className="claimFriends-reward-popup">
            <h3 className="claimFriendspopup-title">{claimSuccessPopup.title}</h3>
            <p className="claimFriendspopup-message">{claimSuccessPopup.message}</p>
            <button
                className="claimFriends-popup-close-button" // Unique class name
                onClick={() => setClaimSuccessPopup({ isVisible: false })}
            >
                Close
            </button>
        </div>
    </div>
)}


            
            <h2 className="friends-page-title">Invite Friends!</h2>
            
            {/* Total Claimable Rewards */}
            <div className="claim-rewards-section">
    <div className="claim-amount-container">
        <span className="currency-symbol">$</span>
        <span className="claim-amount">
            {Math.floor(totalRewards)}<span className="claim-amount-decimal">.{(totalRewards % 1).toFixed(2).substring(2)}</span>
        </span>
    </div>

    {/* Claim button below the amount */}
    <button
        className={`claim-button ${isClaiming || totalRewards === 0 ? 'disabled' : ''}`}
        onClick={handleClaimRewards}
        disabled={isClaiming || totalRewards === 0}
    >
        {isClaiming ? (
            <>
                <span className="spinner"></span> Claiming...
            </>
        ) : (
            'Claim Rewards'
        )}
    </button>
</div>



            {/* Explanation text */}
            <p className="explanation-text">
    {user?.isAffiliate
        ? `Get ${user.affiliatePercentage}% of your friends' winnings every time they win. Start sharing and watch your rewards grow!`
        : 'Get 3.5% of your friends\' winnings every time they win. Start sharing and watch your rewards grow!'
    }
</p>

            {/* Friends Section */}
            <h3 className="friends-count-title">{friends.length} {friends.length === 1 ? 'friend' : 'friends'}</h3>
            <div className="friends-list">
    {friends.length > 0 ? (
        friends.map((friend) => (
            <div key={friend._id} className="friend-item">
                {/* Group avatar and name together */}
                <div className="friend-info">
                    <div className="friend-icon">
                        {friend.avatarUrl ? (
                            <img src={friend.avatarUrl} alt={`${friend.username} avatar`} />
                        ) : (
                            <div className="friend-initial">{friend.username[0].toUpperCase()}</div>
                        )}
                    </div>
                    {/* Friend's name next to avatar */}
                    <p className="friends-page-friend-name">{friend.username}</p>
                </div>
                {/* Friend's earnings on the right */}
                <p 
    className="friend-earnings" 
    style={{ color: friend.earnings > 0 ? '#FFD700' : '#FFFFFF' }} // Yellow if > 0, white if 0
>
    <span className="currency-symbol-on-row">$</span> {friend.earnings.toFixed(2)}
</p>

            </div>
        ))
    ) : (
        <p className="no-friends-message">You haven't invited any friends yet.</p>
    )}
</div>






        </div>
    );
};

export default FriendsPage;
