import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CompletedGamesPage.css';

const CompletedGamesPage = () => {
    const [games, setGames] = useState([]);
    const [totalGames, setTotalGames] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const armeniaOffset = 4 * 60 * 60 * 1000; // UTC+4 in milliseconds

    // Convert dates to Armenia Time (UTC+4)
    const convertToArmeniaTime = (date) => {
        const utcDate = new Date(date.getTime() + armeniaOffset);
        utcDate.setHours(0, 0, 0, 0);
        return utcDate.toISOString();
    };

    // Fetch games from the backend
    const fetchGames = async () => {
        setLoading(true);
        setError('');
        try {
            const formattedStartDate = convertToArmeniaTime(startDate);
            const formattedEndDate = new Date(endDate.getTime() + armeniaOffset).toISOString();

            const response = await fetch(
                `/api/admin/completed-games?startDate=${formattedStartDate}&endDate=${formattedEndDate}&page=${page}&limit=10&search=${debouncedSearchQuery}`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch games');
            }

            const data = await response.json();
            setGames(data.games);
            setTotalGames(data.totalGames);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Debounce search input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 500); // Adjust debounce delay as needed

        return () => clearTimeout(handler);
    }, [searchQuery]);

    // Fetch games whenever filters or search change
    useEffect(() => {
        fetchGames();
    }, [page, startDate, endDate, debouncedSearchQuery]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= Math.ceil(totalGames / 10)) {
            setPage(newPage);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(1); // Reset to the first page when search changes
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleDownloadLog = async (gameId) => {
        try {
            const response = await fetch(`/api/admin/games/${gameId}/log`, {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!response.ok) {
                throw new Error('Failed to download game log');
            }
    
            // Parse the response to get the log content
            const logArray = await response.json();
    
            if (!Array.isArray(logArray) || logArray.length === 0) {
                throw new Error('No log data available');
            }
    
            // Format the log entries for better readability
            const logContent = logArray.join('\n');
    
            // Create a Blob object for the formatted log
            const blob = new Blob([logContent], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
    
            // Create a download link and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `game_log_${gameId}.txt`);
            document.body.appendChild(link);
            link.click();
    
            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading game log:', error.message);
        }
    };
    
    

    return (
        <div className="completed-games-container">
            <h1>Completed Games</h1>
    
            {/* Search Bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by Game ID or Player Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>
    
            {/* Date Range Picker */}
            <div className="date-range-picker">
                <h2>Select Date Range</h2>
                <div className="date-picker-group">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="date-picker"
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="date-picker"
                    />
                    <button className="fetch-games-button" onClick={fetchGames}>
                        Apply
                    </button>
                </div>
            </div>
    
            <p>Total Games: {totalGames}</p>
    
            {error ? (
                <p className="error-message">{error}</p>
            ) : loading ? (
                <p>Loading...</p>
            ) : games.length === 0 ? (
                <p>No games found for the selected criteria.</p>
            ) : (
                // Scrollable Table Wrapper
                <div className="table-container">
                    <table className="games-table">
                        <thead>
                            <tr>
                                <th>Game Name</th>
                                <th>Game ID</th>
                                <th>Player IDs</th>
                                <th>Players</th>
                                <th>Scores</th>
                                <th>Winner</th>
                                <th>Bet Amount</th>
                                <th>Date</th>
                                <th>Duration</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {games.map((game) => (
                                <tr key={game.gameId}>
                                    <td>{game.gameMode}</td>
                                    <td>{game.gameId}</td>
                                    <td>
                                        {game.results.map((player) => (
                                        <div key={player.playerId}>{player.playerId}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {game.results.map((player) => (
                                            <div key={player.playerId}>{player.nickname}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {game.results.map((player) => (
                                            <div key={player.playerId}>{player.score}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {game.results
                                            .filter((player) => player.isWinner)
                                            .map((winner) => winner.nickname)
                                            .join(', ')}
                                    </td>
                                    <td>${game.betAmount}</td>
                                    <td>{new Date(game.createdAt).toLocaleString()}</td>
                                    <td>{formatDuration(game.duration)}</td>
                                    <td>
                                        <button
                                        className="downloadLog-button"
                                        onClick={() => handleDownloadLog(game.gameId)}
                                        >
                                            Download Log
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
    
            {/* Pagination */}
            <div className="pagination">
                <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                    Previous
                </button>
                <span>Page {page}</span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= Math.ceil(totalGames / 10)}
                >
                    Next
                </button>
            </div>
        </div>
    );
    
};

export default CompletedGamesPage;
