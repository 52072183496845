import React from 'react';
import './LoadingScreen.css'; // Import the CSS for LoadingScreen

const LoadingScreen = () => (
    <div className="loading-screen-overlay">
        <div className="loading-screen-content">
            <p>Loading game assets...</p>
            <div className="loading-spinner"></div>
        </div>
    </div>
);

export default LoadingScreen;