import React, { useState, useEffect, useContext } from 'react';
import { apiRequest } from '../api';
import { AuthContext } from '../context/AuthContext';
import './AffiliateProgram.css'; // Import the CSS file for this page
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../apiConfig';

const AffiliateProgramForm = () => {
    const { user } = useContext(AuthContext);
    const [channelName, setChannelName] = useState('');
    const [email, setEmail] = useState('');
    const [additionalNotes, setAdditionalNotes] = useState(''); // New field for additional notes
    const [message, setMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // Loading state
   

    

    const escapeHTML = (input) => {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;',
        };
        return input.replace(/[&<>"']/g, (char) => map[char]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true); // Start loading

        try {

             // Sanitize inputs before sending them
        const sanitizedChannelName = escapeHTML(channelName);
        const sanitizedEmail = escapeHTML(email);
        const sanitizedAdditionalNotes = escapeHTML(additionalNotes);
 
            const response = await apiRequest(
                `${API_BASE_URL}/api/affiliate-program/request`, 
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        channelName: sanitizedChannelName,
                        email: sanitizedEmail,
                        additionalNotes: sanitizedAdditionalNotes
                    }),
                });

            const data = await response.json();
            if (response.ok) {
                setMessage('Your request has been submitted successfully!');
            } else {
                setMessage(data.message || 'Failed to submit your request.');
            }
        } catch (error) {
            setMessage('Error submitting the request. Please try again later.');
        } finally {
            setIsProcessing(false); // Stop loading
        }
    };

    return (
        <div className="affiliate-program-container-unique">
            <h2 className="affiliate-program-title-unique">Affiliate Program</h2>
            <p className="affiliate-program-description-unique">
                Standard affiliate bonus is 3.5%. If you have a large audience on a public channel (Telegram, YouTube, or other social media platforms), you may qualify for a special rate up to 10%. 
                Fill out the form below, and our team will review your request.
            </p>
            <form onSubmit={handleSubmit} className="affiliate-program-form-unique">
                <div className="affiliate-form-group-unique">
                    <label>Channel/Page Name:</label>
                    <input
                        type="text"
                        value={channelName}
                        onChange={(e) => setChannelName(e.target.value)}
                        required
                        className="affiliate-input-field-unique"
                        placeholder="Enter your channel or page name"
                    />
                </div>
                <div className="affiliate-form-group-unique">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="affiliate-input-field-unique"
                        placeholder="Enter your contact email"
                    />
                </div>
                <div className="affiliate-form-group-unique">
                    <label>Additional Notes (Optional):</label>
                    <textarea
                        value={additionalNotes}
                        onChange={(e) => setAdditionalNotes(e.target.value)}
                        maxLength="200"
                        className="affiliate-input-field-unique affiliate-textarea-field-unique"
                        placeholder="Add any additional information (200 characters max)"
                    />
                </div>
                {message && <p className="affiliate-success-message-unique">{message}</p>}
                <button
                    type="submit"
                    className={`affiliate-submit-button-unique ${isProcessing ? 'affiliate-button-disabled-unique' : ''}`}
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <>
                            <span className="affiliate-spinner-unique"></span> Submitting...
                        </>
                    ) : "Submit Request"}
                </button>
            </form>
            
        </div>
    );
};

export default AffiliateProgramForm;
