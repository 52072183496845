import React, { useState, useEffect, useContext } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { apiRequest } from '../api';
import { AuthContext } from '../context/AuthContext';
import API_BASE_URL from '../apiConfig';
import './LoginForm.css';


const escapeHTML = (input) => {
    const div = document.createElement('div');
    div.textContent = input;
    return div.innerHTML;
};

const LoginForm = () => {
    const {user, fetchUserDetails } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setLoading] = useState(true); // New loading state
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false); 

  
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referrerId = params.get('referrerId');

        // Store referrerId in local storage if available
        if (referrerId) {
            localStorage.setItem('referrerId', referrerId);
        }
    }, [location]);

    

    useEffect(() => {
        // Retrieve referrerId from local storage
        const referrerId = localStorage.getItem('referrerId');
        const authUrl = referrerId 
            ? `${API_BASE_URL}/api/auth/telegram?referrerId=${referrerId}` 
            : `${API_BASE_URL}/api/auth/telegram`;
    
        // Telegram login widget script setup
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?7';
        script.setAttribute('data-telegram-login', 'USDT_BLOT_bot');
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-auth-url', authUrl); // Use authUrl with referrerId if available
        script.setAttribute('data-request-access', 'write');
        script.setAttribute('data-radius', '10');
        script.setAttribute('data-lang', 'en');
        script.async = true;
    
        const container = document.getElementById('telegram-login-container');
        container.appendChild(script);
    
        // Hide the loading screen after the Telegram button loads
        script.onload = () => {
            setTimeout(() => setLoading(false), 300);
        };
    
        return () => {
            container.removeChild(script);
        };
    }, [location]);

    useEffect(() => {
        const initData = window.Telegram?.WebApp?.initData;
        if (initData && !isLoggedIn) {  // Prevents repeated auto-login
            handleAutoLogin(initData);
        }
    }, [isLoggedIn]);


    const handleAutoLogin = async (initData) => {

        
        setIsProcessing(true);
        setLoading(true)

        
        // Retrieve referrerId from local storage
        const referrerId = localStorage.getItem('referrerId');
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/auth/telegram`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ initData,referrerId  }),
            });
            
            const data = await response.json();
            if (response.ok) {
                await fetchUserDetails();
                setIsLoggedIn(true);
                
                navigate('/game', { replace: true });
                                
            } else {
                setMessage(data.message || 'Login failed');
                console.log("Auto-login failed");
            }
        } catch (error) {
            setMessage('Login failed due to server error');
        } finally {
            setIsProcessing(false);
            setLoading(false);
         }
    };
    
 
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true);

        const sanitizedUsername = escapeHTML(username);

        try {
            const response = await apiRequest(`${API_BASE_URL}/api/login`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: sanitizedUsername, password }),
            });

            const data = await response.json();
            if (response.ok) {
                await fetchUserDetails();
                navigate('/game', { replace: true });
            } else {
                setMessage(data.message || 'Login failed');
            }
        } catch (error) {
            setMessage('Login failed due to server error');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="login-container">
            
            {loading && <div id="login-loading-screen" className="login-loading-screen">Loading...</div>}
                       
            <h2 className="login-title">Login</h2>
            <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="input-field"
                    />
                </div>
                <div className="form-group">
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="input-field"
                    />
                </div>
                <button 
                    type="submit"
                    className={`login-button ${isProcessing ? 'login-disabled' : ''}`}
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <>
                            <span className="spinner"></span> Logging in...
                        </>
                    ) : "Login"}
                </button>

                {/* Telegram Login Button Container */}
                <div id="telegram-login-container" className="telegram-login-container"></div>
            </form>
            {message && <p className="error-message">{message}</p>}

            <div className="login-options">
                <button className="link-button" onClick={() => navigate('/forgot-password')}>
                    Forgot Password?
                </button>
                <button className="link-button" onClick={() => navigate('/register')}>
                    Don't have an account? Register
                </button>
            </div>
        </div>
    );
};

export default LoginForm;
