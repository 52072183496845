import React, { useState, useEffect, useContext } from 'react';
import { apiRequest } from '../api';
import './TransactionHistory.css';
import { AuthContext } from '../context/AuthContext';
import API_BASE_URL from '../apiConfig';

const TransactionHistory = () => {
    const { user } = useContext(AuthContext);
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('all');
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                setLoading(true);
                     const response = await apiRequest(`${API_BASE_URL}/api/transactions/${user._id}` , {
                        method: 'GET',
                        credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setTransactions(data.transactions);
                } else {
                    setError('Failed to fetch transactions');
                }
            } catch (err) {
                setError('Error fetching transactions');
            } finally {
                setLoading(false);
            }
        };

        if (user && user._id) {
            fetchTransactions();
        }
    }, [user]);

    const filteredTransactions = transactions
    .filter((transaction) => 
        (transaction.type === 'deposit' || 
         transaction.type === 'withdrawal' || 
         transaction.type === 'claim-reward' || 
         transaction.type === 'task-reward')  
        && transaction.amount > 0
    )
        .filter((transaction) => {
            if (filter === 'all') return true;
            if (filter === 'claim-reward') {
                return transaction.type === 'claim-reward' || transaction.type === 'task-reward';
            }
            return transaction.type === filter;
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const groupedTransactions = filteredTransactions.reduce((acc, transaction) => {
        const date = new Date(transaction.date).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
        });

        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(transaction);
        return acc;
    }, {});

    const handleTransactionClick = (transaction) => {
        setSelectedTransaction(transaction);
        const scrollableContent = document.getElementById('scrollableContent');
        if (scrollableContent) {
            setScrollPosition(scrollableContent.scrollTop); // Capture scroll position
            scrollableContent.style.overflow = 'hidden'; // Lock scroll on main content
        }
    };

    const handleClosePopup = () => {
        setSelectedTransaction(null);
        const scrollableContent = document.getElementById('scrollableContent');
        if (scrollableContent) {
            scrollableContent.style.overflow = 'auto'; // Restore scroll on main content
        }
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    if (loading) {
        return <div className="loading-message">Loading...</div>;
    }

    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="transaction-history-container">
            <h2 className="history-page-title">History</h2>

           
            <div className="filter-container">
                <label htmlFor="filter">Filter: </label>
                <select id="filter" value={filter} onChange={handleFilterChange}>
                    <option value="all">All</option>
                    <option value="deposit">Deposits</option>
                    <option value="withdrawal">Withdrawals</option>
                    <option value="claim-reward">Rewards</option>
               
                </select>
            </div>

           
            <div className="transaction-fullList">
            {Object.keys(groupedTransactions).length > 0 ? (
                Object.keys(groupedTransactions).map((date) => (
                    <div key={date} className="transaction-group">
                        <h3 className="transaction-date">{date}</h3>
                        {groupedTransactions[date].map((transaction) => (
                            <div
                                key={transaction._id}
                                className="transaction-item"
                                onClick={() => handleTransactionClick(transaction)}
                            >
                                <div className="transaction-info">
                                    <span className="transaction-amount">
                                        {transaction.type === 'deposit' || transaction.type === 'claim-reward' || transaction.type === 'task-reward' ? '+' : '-'}$
                                        {(Math.floor(transaction.amount * 100) / 100).toFixed(2)}
                                    
                                    </span>
                                    <span className="transaction-type">
                                        {transaction.type === 'claim-reward' ? 'Reward Collection' : transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                                    </span>
                                    <span className="transaction-status">
                                        Status:
                                        <span className={`status-name ${transaction.status}`}>
                                            {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                                        </span>
                                    </span>
                                </div>
                                <img src="/icons/arrow-icon.svg" alt="Arrow Icon" className="transaction-arrow" />
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <div className="empty-message">No transactions available</div>
            )}
            </div>

            {selectedTransaction && (
                <div
                    className="transaction-popup-overlay"
                    style={{ top: `${scrollPosition}px` }} // Apply scroll position as top offset
                    onClick={handleClosePopup}
                >
                    <div className="transaction-popup" onClick={(e) => e.stopPropagation()}>
                        <div className="transaction-popup-header">
                            <h3 className="transaction-popup-title">Transaction Details</h3>
                            <button className="transaction-close-btn" onClick={handleClosePopup}>
                                &times;
                            </button>
                        </div>
                        <hr className="popup-separator" />
                        <div className="popup-body">
                            <p>
                                <span className="row-label">Amount:</span>{' '}
                                <span className="row-data">
                                    ${(Math.floor(selectedTransaction.amount * 100) / 100).toFixed(2)}
                                </span>
                            </p>
                            {selectedTransaction.type === 'withdrawal' && (
                                <p>
                                    <span className="row-label">Network Fee:</span> <span className="row-data">$1.50</span>
                                </p>
                            )}
                            <p>
                                <span className="row-label">Type:</span>{' '}
                                <span className="row-data">
                                    {selectedTransaction.type === 'claim-reward'
                                        ? 'Reward Collection'
                                        : selectedTransaction.type.charAt(0).toUpperCase() + selectedTransaction.type.slice(1)}
                                </span>
                            </p>
                            <p>
                                <span className="row-label">Status:</span>{' '}
                                <span className={`row-data status-${selectedTransaction.status}`}>
                                    {selectedTransaction.status.charAt(0).toUpperCase() + selectedTransaction.status.slice(1)}
                                </span>
                            </p>
                            {selectedTransaction.blockchainTransactionId && (
                                <p>
                                    <span className="row-label">Blockchain Transaction ID:</span>{' '}
                                    <span className="row-data">{selectedTransaction.blockchainTransactionId}</span>
                                </p>
                            )}
                            {selectedTransaction.date && (
                                <p>
                                    <span className="row-label">Date and Time:</span>{' '}
                                    <span className="row-data">{new Date(selectedTransaction.date).toLocaleString()}</span>
                                </p>
                            )}
                            <p>
                                <span className="row-label">Transaction ID:</span>{' '}
                                <span className="row-data">{selectedTransaction.transactionId}</span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransactionHistory;
