import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { apiRequest } from '../api';
import API_BASE_URL from '../apiConfig';
import './ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // To handle loading state
    const [showPopup, setShowPopup] = useState(false); // To handle popup state
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setIsProcessing(true);
        setError('');
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/forgot-password` , {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage('Password reset link has been sent to your email.');
                setShowPopup(true); // Show popup when link is sent
            } else {
                setError(data.message || 'Failed to send reset link.');
            }
        } catch (err) {
            setError('An error occurred while sending the reset link.');
            console.error('Error:', err);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleGoToLogin = () => {
        navigate('/login'); // Navigate to login page
    };

    return (
        <div className="forgot-password-page-container">
            <h2 className="forgot-password-page-title">Reset Password</h2>
            <p className="forgot-password-description-text">
                Please enter the email address associated with your account. We will send you a link to reset your password.
            </p>
            <form onSubmit={handleSubmit} className="forgot-password-page-form">
                <div className="forgot-password-form-group">
                    <label htmlFor="email">Email Address:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        className="forgot-password-input-field"
                        required
                    />
                </div>
                <button 
                    type="submit" 
                    className={`forgot-password-submit-button ${isProcessing ? 'disabled' : ''}`} 
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <>
                            <span className="forgot-password-spinner"> </span>  Sending...
                        </>
                    ) : "Send Reset Link"}
                </button>
            </form>
            {error && <p className="forgot-password-error-message">{error}</p>}
            {message && <p className="forgot-password-success-message">{message}</p>}

            {/* Popup for Successful Email Sent */}
            {showPopup && (
                <div className="forgot-password-popup-overlay">
                    <div className="forgot-password-popup">
                        <h3>Link Sent!</h3>
                        <p>A reset link has been sent to your email address. Please check your inbox.</p>
                        <button className="forgot-password-popup-button" onClick={handleGoToLogin}>
                            Go to Login
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ForgotPassword;
