import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import { QRCodeCanvas } from 'qrcode.react';
import API_BASE_URL from '../apiConfig';
import './Deposit.css'; // Import CSS for styling


const Deposit = ({setFooterVisible}) => {
    const { user } = useContext(AuthContext);
    const [walletAddress, setWalletAddress] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [message, setMessage] = useState('');
    const [copied, setCopied] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const trxRate = 0.151; // Example TRX to USDT rate (1 USDT = 0.066 TRX)

const getTRXAmount = (usdtAmount) => {
  return Math.ceil(usdtAmount / trxRate); // Calculate and round up
};


    useEffect(() => {
        const fetchWalletAddress = async () => {
            try {
                const response = await apiRequest(`${API_BASE_URL}/api/wallet/wallet-address` , {
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                if (response.ok) {
                    setWalletAddress(data.walletAddress);
                } else {
                    setMessage(`Failed to fetch wallet address: ${data.error}`);
                }
            } catch (err) {
                setMessage('Error fetching wallet address');
            }
        };
        fetchWalletAddress();
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(walletAddress).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }, (err) => {
            setMessage('Could not copy text');
        });
    };

    const openPopup = (currency) => {
        setSelectedCurrency(currency);
        setShowPopup(true);
        setFooterVisible(false);
    };

    const handleClosePopup = () => {
        setIsClosing(true);
        setFooterVisible(true);
        setTimeout(() => {
            setShowPopup(false);
            setIsClosing(false);
        }, 200); // The duration should match the slideDown animation time (0.3s)
    };

    return (
        <div className="deposit-container">
        {/* Page Title */}
        <h1 className="deposit-title">Deposit</h1>

        {/* How to Deposit Section */}
        <div className="instructions-section">
            <h3 className="instructions-title">How to deposit?</h3>
            <div className="instructions-border">
                <div className="instruction-item">
                    <div className="instruction-number">1</div>
                    <span>Choose your currency</span>
                </div>
                <div className="instruction-item">
                    <div className="instruction-number">2</div>
                    <span>Send crypto to the provided address</span>
                </div>
                <div className="instruction-item">
                    <div className="instruction-number">3</div>
                    <span>Your balance updates right after network confirmation — typically around 5 minutes.</span>
                </div>
            </div>
        </div>

            {/* Currency Section */}
            <div className="currency-section">
                <div className="currency-option" onClick={() => openPopup('USDT')}>
                    <img src="/icons/usdt.svg" alt="USDT Icon" className="currency-icon" />
                    <span>USDT (TRC20)</span>
                </div>
                <div className="currency-option" onClick={() => openPopup('TRX')}>
                    <img src="/icons/trx.svg" alt="TRX Icon" className="currency-icon" />
                    <span>TRX</span>
                </div>
                <div className="currency-option coming-soon">
  <img src="/icons/ton.svg" alt="TON Icon" className="currency-icon" />
  <span>TON</span>
  <div style={{ fontSize: '12px', color: 'gray' }}>(Coming Soon)</div>
</div>
            </div>

 


         {/* Deposit Popup */}
         {showPopup && (
                <div className="deposit-popup-overlay" onClick={handleClosePopup}>
                    <div className={`deposit-popup-container ${isClosing ? 'closing' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className="deposit-popup">
                            <div className="deposit-popup-header">
                                <img src={`/icons/${selectedCurrency.toLowerCase()}.svg`} alt={`${selectedCurrency} Icon`} className="deposit-popup-currency-icon" />
                                <h3 className="deposit-popup-currency-label">{selectedCurrency}</h3>
                                <button className="deposit-popup-close" onClick={handleClosePopup}>&times;</button>
                            </div>
                            <p className="deposit-popup-warning">
                                {selectedCurrency === 'TRX'
                                    ? `Send only ${selectedCurrency} to this address. Otherwise, you may lose your funds.`
                                    : `Send only ${selectedCurrency} in TRC20 network to this address. Otherwise, you may lose your funds.`
                                }
                            </p>
                            <div className="qr-section">
                                <QRCodeCanvas value={walletAddress} size={160} fgColor="#FFFFFF" bgColor="#282828" />
                                <p className="wallet-address-text">{walletAddress}</p>
                            </div>
                            <button onClick={handleCopy} className="copy-button">
                                <img src="/icons/copy-icon.svg" alt="Copy Icon" className="copy-icon" /> Copy
                            </button>
                            {copied && <p className="copied-message">Address copied to clipboard!</p>}
                            <p className="minimum-amount">
                                {selectedCurrency === 'TRX'
                                    ? <>
                                        <span className="normal-text">Recommended deposit amount: </span>
                                        <span className="bold-text">{getTRXAmount(20)} TRX</span>
                                        <p className="rate-info">1 TRX ≈ {trxRate.toFixed(3)} USDT</p>
                                    </>
                                    : <>
                                        <span className="normal-text">Recommended deposit amount: </span>
                                        <span className="bold-text">20 {selectedCurrency}</span>
                                    </>
                                }
                            </p>
                        </div>
                    </div>
                </div>
            
)}



            {/* Error/Message Section */}
            {message && <p className="error-message">{message}</p>}
        </div>
    );
};

export default Deposit;
