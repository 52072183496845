import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import './UsersPage.css';

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(searchQuery);
    const [minBalance, setMinBalance] = useState('');

    const navigate = useNavigate();

    // Debounce effect for search input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(searchQuery);
        }, 500); // Wait for 500ms before updating search query

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `/api/admin/users?page=${page}&limit=10&search=${debouncedSearch}&minBalance=${minBalance}`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }

            const data = await response.json();

            setUsers(data.users || []);
            setTotalUsers(data.totalUsers || 0);
            setError('');
        } catch (err) {
            console.error('Error fetching users:', err.message);
            setError('Failed to fetch users. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [page, debouncedSearch, minBalance]);
    


    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= Math.ceil(totalUsers / 10)) {
            setPage(newPage);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setPage(1); // Reset to first page when search changes
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        return date.toLocaleString();
    };

    const handleViewTransactions = async (userId) => {
        try {
            const response = await fetch(`/api/admin/users/${userId}/transactions/export`, {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!response.ok) {
                throw new Error('Failed to download transactions');
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `transactions_${userId}_${new Date().toISOString()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading transactions:', error.message);
        }
    };

    const handleMinBalanceChange = (e) => {
        setMinBalance(e.target.value);
        setPage(1); 
    };

    const handleExport = async () => {
        try {
            const response = await fetch(`/api/admin/users/export?search=${debouncedSearch}&minBalance=${minBalance}`, {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch users for export');
            }
    
            const data = await response.json();
    
            // Prepare and download the file
            const worksheet = XLSX.utils.json_to_sheet(data.users);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
            XLSX.writeFile(workbook, `users_export_${new Date().toISOString()}.xlsx`);
        } catch (error) {
            console.error('Error exporting users:', error.message);
            setError('Failed to export users.');
        }
    };


    return (
        <div className="users-page-container">
            <h1>Users</h1>

            <div>
    {/* Search Bar */}
    <div className="search-bar">
        <input
            type="text"
            placeholder="Search by User ID, Nickname, or Inviter"
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
        />
    </div>

    {/* Min Balance Filter */}
    <div className="min-balance-filter">
        <input
            type="number"
            placeholder="Min Balance"
            value={minBalance}
            onChange={handleMinBalanceChange}
            className="min-balance-input"
        />
    </div>
</div>

<button className="export-buttonUsers" onClick={handleExport}>
                Export to Excel
            </button>

            {/* Users Table */}
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : users.length === 0 ? (
                <p>No users found for the current criteria.</p>
            ) : (
                <div className="users-table-wrapper">
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>Nickname</th>
                                <th>Balance</th>
                                <th>Blockchain Balance</th>
                                <th>Blockchain TRX Balance</th>
                                <th>Created At</th>
                                <th>Inviter</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user._id}>
                                    <td>{user._id}</td>
                                    <td>{user.nickname}</td>
                                    <td>${user.balance.toFixed(2)}</td>
                                    <td>${user.blockchainBalance.toFixed(2)}</td>
                                    <td>${user.blockchainBalanceTRX.toFixed(2)}</td>
                                    <td>{formatDate(user.createdAt)}</td>
                                    <td>{user.invitedBy}</td>
                                    <td>
                                        <button
                                            className="transactions-button"
                                            onClick={() => handleViewTransactions(user._id)}
                                        >
                                            Transactions
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Pagination */}
            <div className="pagination">
                <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                    Previous
                </button>
                <span>
                    Page {page} of {Math.ceil(totalUsers / 10)}
                </span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= Math.ceil(totalUsers / 10)}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default UsersPage;
