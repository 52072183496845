import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiRequest } from '../api';
import './ResetPassword.css';
import API_BASE_URL from '../apiConfig';

const ResetPassword = () => {
    const { token } = useParams(); 
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPopup, setShowPopup] = useState(false); 
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            setIsProcessing(true);
            const response = await apiRequest(`${API_BASE_URL}/api/reset-password` , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, password }), 
            });

            const data = await response.json();

            if (response.ok) {
                setMessage('Your password has been successfully reset.');
                setShowPopup(true); 
            } else {
                setError(data.message || 'Failed to reset password');
            }
        } catch (err) {
            setError('An error occurred while resetting the password.');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleGoToLogin = () => {
        navigate('/login'); 
    };

    return (
        <div className="reset-password-page-container">
            <h2 className="reset-password-page-title">Reset Your Password</h2>
            <p className="reset-password-guideline-text">
                Please enter a new password and confirm it. Make sure your password is strong and secure.
            </p>
            <form onSubmit={handleSubmit} className="reset-password-form">
                <div className="reset-password-form-group">
                    <label htmlFor="password">New Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        className="reset-password-input-field"
                    />
                </div>
                <div className="reset-password-form-group">
                    <label htmlFor="confirm-password">Confirm New Password</label>
                    <input
                        type="password"
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                        className="reset-password-input-field"
                    />
                </div>
                <button 
                    type="submit" 
                    className={`reset-password-submit-button ${isProcessing ? 'disabled' : ''}`} 
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <>
                            <span className="reset-password-spinner"></span> Resetting...
                        </>
                    ) : "Reset Password"}
                </button>
            </form>
            {error && <p className="reset-password-error-message">{error}</p>}
            {message && <p className="reset-password-success-message">{message}</p>}

            
            {showPopup && (
                <div className="reset-password-popup-overlay">
                    <div className="reset-password-popup">
                        <h3>Password Reset Successfully!</h3>
                        <p>Your password has been reset. You can now log in with your new password.</p>
                        <button className="reset-password-popup-button" onClick={handleGoToLogin}>
                            Go to Login
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResetPassword;
