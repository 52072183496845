import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import API_BASE_URL from '../apiConfig';


import './ScoreHistory.css';

const ScoreHistory = () => {
    const { user } = useContext(AuthContext);
    const [scoreHistory, setScoreHistory] = useState([]);
    const [totalGames, setTotalGames] = useState(0);
    const [winPercentage, setWinPercentage] = useState(0);
    const [losePercentage, setLosePercentage] = useState(0);
    const [wins, setWins] = useState(0);
    const [losses, setLosses] = useState(0);

    useEffect(() => {
        const fetchScoreHistory = async () => {
            try {
                const response = await apiRequest(`${API_BASE_URL}/api/score-history` , {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                const data = await response.json();
                console.log('Raw response:', data);

                if (response.ok) {
                    setScoreHistory(data);
                    setTotalGames(data.length);
                    
                 
                    const winCount = data.filter(entry => entry.result === 'win').length;
                    const lossCount = data.filter(entry => entry.result === 'lose').length;
                    
                    setWins(winCount);
                    setLosses(lossCount);
                    
                    setWinPercentage(((winCount / data.length) * 100).toFixed(2));
                    setLosePercentage(((lossCount / data.length) * 100).toFixed(2));
                } else {
                    console.error('Failed to fetch score history:', data.message);
                }
            } catch (err) {
                console.error('Error fetching score history:', err);
            }
        };

        if (user) {
            fetchScoreHistory();
        }
    }, [user]);

    const data = {
        labels: scoreHistory.map(entry => new Date(entry.date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })),
        datasets: [
            {
                label: 'Score Over Time',
                data: scoreHistory.map(entry => entry.score),
                fill: true,
                borderColor: '#4A90E2',
                tension: 0.1,
                backgroundColor: 'rgba(74, 144, 226, 0.2)',
                borderWidth: 2,
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const date = new Date(scoreHistory[context.dataIndex].date).toLocaleString();
                        const score = context.raw;
                        return `Date: ${date}, Score: ${score}`;
                    }
                }
            }
        },
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        }
    };

    return (
        <div className="score-history-container">
            <h2 className="page-title">Score History</h2>
            {user && (
                <div className="player-info">
                    <p><strong>Player Name:</strong> {user.username}</p>
                    <p><strong>Current Score:</strong> {user.score}</p>
                    <div className="percentage-circles">
                        <div className="circle-container">
                            <svg width="60" height="60" viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="45" stroke="lightgray" strokeWidth="10" fill="none" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="45"
                                    stroke="green"
                                    strokeWidth="10"
                                    fill="none"
                                    strokeDasharray={`${winPercentage * 2.82} ${282 - winPercentage * 2.82}`}
                                    strokeDashoffset="25"
                                    transform="rotate(-90 50 50)"
                                />
                                <text x="50" y="55" textAnchor="middle" fontSize="16" fill="green">
                                    <tspan fontSize="20" fontWeight="bold">{Math.floor(winPercentage)}</tspan>
                                    <tspan fontSize="12">.{(winPercentage % 1).toFixed(2).split('.')[1]}%</tspan>
                                </text>
                            </svg>
                            <p>Wins {wins}</p>
                        </div>
                        <div className="circle-container">
                            <svg width="60" height="60" viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="45" stroke="lightgray" strokeWidth="10" fill="none" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="45"
                                    stroke="red"
                                    strokeWidth="10"
                                    fill="none"
                                    strokeDasharray={`${losePercentage * 2.82} ${282 - losePercentage * 2.82}`}
                                    strokeDashoffset="25"
                                    transform="rotate(-90 50 50)"
                                />
                                <text x="50" y="55" textAnchor="middle" fontSize="16" fill="red">
                                    <tspan fontSize="20" fontWeight="bold">{Math.floor(losePercentage)}</tspan>
                                    <tspan fontSize="12">.{(losePercentage % 1).toFixed(2).split('.')[1]}%</tspan>
                                </text>
                            </svg>
                            <p>Losses {losses}</p>
                        </div>
                        <div className="circle-container">
                            <svg width="60" height="60" viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="45" stroke="black" strokeWidth="10" fill="none" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="45"
                                    stroke="black"
                                    strokeWidth="10"
                                    fill="none"
                                    strokeDasharray={`282 0`}
                                    strokeDashoffset="25"
                                    transform="rotate(-90 50 50)"
                                />
                                <text x="50" y="55" textAnchor="middle" fontSize="20" fill="white" fontWeight="bold">{totalGames}</text>
                            </svg>
                            <p>Total Games</p>
                        </div>
                    </div>
                </div>
            )}
            {scoreHistory.length > 0 ? (
                <div className="chart-container">
                   
                </div>
            ) : (
                <p>No score history available.</p>
            )}
        </div>
    );
};

export default ScoreHistory;
