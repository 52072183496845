import React, { useEffect, useState, useContext, useCallback,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './CarromGame.css';
import audioManager from './AudioManager';

const CarromGame = () => {
    const { socket, user } = useContext(AuthContext);

    // State variables
    const [game, setGame] = useState(null);
    const navigate = useNavigate();
    const [opponents, setOpponents] = useState([]);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [currentRound, setCurrentRound] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const [isPlayerTurn, setIsPlayerTurn] = useState(true);
    const STRIKER_SIZE = 28.2;
    const COIN_SIZE = 18.8;
    const STRIKER_RADIUS = STRIKER_SIZE / 2; 
    const COIN_RADIUS = COIN_SIZE / 2;
    
    




    
     

    // Fetch game state from API
    const fetchGameState = useCallback(async () => {
        try {
            const response = await apiRequest('/api/current-game', {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();
            console.log('data on game fetch', data)
            if (response.ok && data.game) {
                if (data.game.status === 'completed') {
                    navigate('/game');
                    return;
                }

                setGame(data.game);
                setCurrentRound(data.game.currentRound);
                setOpponents(data.opponents || []);
                setCoins(data.game.boardState.coins || []);
                coinsRef.current = data.game.boardState.coins || [];
                handleGamePhase(data.game);
            } else {
                setGame(null);
                setPopupMessage('Failed to fetch the current game state.');
                return;
            }
        } catch (error) {
            console.error('Error fetching game state:', error);
        }
    }, [navigate]);

    // Handle game updates from socket
    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }

        socket.on('game-update', (data) => {
            if (!data.game) {
                navigate('/game');
                return;
            }
            console.log('data on game update', data)
            setGame(data.game);
            setCurrentRound(data.game.currentRound);
            setOpponents(data.opponents || []);
            setCoins(data.game.boardState.coins || []);
            coinsRef.current = data.game.boardState.coins || [];
            handleGamePhase(data.game);
        });

        return () => {
            socket.off('game-update');
        };
    }, [socket, user, navigate]);

    // Handle game phases
    const handleGamePhase = (gameData) => {
        switch (gameData.phase) {
            case 'setup':
                // Initial setup phase
                setCurrentRound(1);
                break;

            case 'play':
                // Main gameplay phase
                setCurrentRound(gameData.currentRound);
                break;

            case 'round-completed': {
                // Handle round completion
                setCurrentRound(gameData.currentRound);
                break;
            }

            case 'game-canceled': {
                // Handle game cancellation
                setShowCancelPopup(true);
                setTimeout(() => {
                    setShowCancelPopup(false);
                    navigate('/game');
                }, 4000);
                break;
            }

            case 'game-completed':
                setIsGameEnd(true);
                break;

            default:
                console.warn(`Unhandled game phase: ${gameData.phase}`);
        }
    };

    // Fetch initial game state
    useEffect(() => {
        fetchGameState();
    }, [fetchGameState]);

    // Handle game end animations and cleanup
    useEffect(() => {
        if (game && user && isGameEnd) {
            if (game.winners?.includes(user._id)) {
                audioManager.playSound('win');
            } else {
                audioManager.playSound('lose');
            }

            const timeout = setTimeout(() => {
                navigate('/game');
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [isGameEnd, game, user, navigate]);

    

    const renderAvatar = (name, photoUrl) => {
        if (photoUrl) {
            return <img src={photoUrl} alt={`${name}'s Avatar`} className="carrom-avatar-image" />;
        }
        const initial = name.charAt(0).toUpperCase();
        return <div className="carrom-avatar-placeholder">{initial}</div>;
    };
    
    const renderOpponentArea = (game, user, opponents) => {
        const opponent = opponents.find(opponent => opponent._id !== user._id);
    
        if (!opponent) {
            return null; // No opponent found
        }
    
        const opponentPoints = game.totalGamePoints[opponent._id] || 0;
        const opponentColor = game.playerColors[opponent._id]; // Get opponent's assigned color
        const opponentPocketedCoins = game.pocketedPieces[opponent._id]?.length || 0; // Count of pocketed coins
    
    
        return (
            <div className="carrom-opponent-wrapper">
                <div className="carrom-opponent-area">
                    {/* Opponent Avatar */}
                    <div className="carrom-opponent-avatar">
                        {renderAvatar(opponent.nickname, opponent.photoUrl)}
                    </div>
    
                    {/* Opponent Info */}
                    <div className="carrom-opponent-info">
                        <div className="carrom-name-and-flag">
                            <span className="carrom-opponent-name">{opponent.nickname}</span>
                            {opponent.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="carrom-country-flag"
                                />
                            )}
                        </div>
                        <span className="carrom-opponent-score">Score: {opponent.score}</span>
                        <span className="carrom-opponent-betAmmount">Bet: $ {game?.betAmount}</span>
                    </div>
                    
                    <div className="carrom-opponent-coins">
    {/* Render the red coin (queen) if pocketed */}
    {game.pocketedPieces[opponent._id]?.includes('queen') && (
        <div
            className="carrom-opponent-coin-icon"
            style={{
                backgroundImage: `url(/carromAssets/queen-coin.png)`,
            }}
        ></div>
    )}

    {/* Render the opponent's color coin */}
    <div
        className="carrom-opponent-coin-icon"
        style={{
            backgroundImage: `url(/carromAssets/${opponentColor}-coin.png)`,
        }}
    ></div>
    <div className="carrom-opponent-coin-count">
        {game.pocketedPieces[opponent._id]?.filter(coin => coin === opponentColor).length || 0}
    </div>
</div>

                </div>
            </div>
        );
    };


    const [coins, setCoins] = useState([]);
    const [strikerPosition, setStrikerPosition] = useState({ x: 150, y: 256.5 });
    const [isDragging, setIsDragging] = useState(false); // Dragging state
    const [handlePosition, setHandlePosition] = useState(100); // Horizontal position of the handle in the controller
   


    const controllerWidth = 200; // Width of the controller
    const handleWidth = 30; // Width of the handle
    const boardMinX = 65; // Minimum striker X on the board
    const boardMaxX = 235; // Maximum striker X on the board

    // Calculate initial handle position in the controller
    useEffect(() => {
    if (!isControllerActive) return;
    const initialHandlePosition =
        ((strikerPosition.x - boardMinX) / (boardMaxX - boardMinX)) * (controllerWidth - handleWidth);
    setHandlePosition(initialHandlePosition);
    }, [strikerPosition.x]);

    const handleDragStart = (event) => {
        setIsDragging(true);
    };

    const handleDragMove = (event) => {
        if (!isDragging || !isControllerActive) return;
    
        // Get event coordinates (for both mouse and touch)
        const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
    
        // Get controller dimensions
        const controller = document.querySelector(".striker-controller");
        const controllerRect = controller.getBoundingClientRect();
        const controllerLeft = controllerRect.left;
    
        // Calculate relative position of the handle
        const relativeX = clientX - controllerLeft - handleWidth / 2; // Adjust for handle's center
        const constrainedX = Math.max(0, Math.min(controllerWidth - handleWidth, relativeX));
    
        // Update handle position in the controller
        setHandlePosition(constrainedX);
    
        // Map controller handle position to board striker position
        const mappedX =
            boardMinX + (constrainedX / (controllerWidth - handleWidth)) * (boardMaxX - boardMinX);
    
        // Update striker position on the board
        setStrikerPosition({ x: mappedX, y: strikerPosition.y });
    };
    
    const handleDragEnd = () => {
        setIsDragging(false);
    };










    const [isTouchingStriker, setIsTouchingStriker] = useState(false);
    const [isControllerActive, setIsControllerActive] = useState(true);
    const [dragDots, setDragDots] = useState([]); // Dots array for striker interaction
    const [circleScale, setCircleScale] = useState(1); // Initial scale
    const [hitArrow, setHitArrow] = useState({ angle: 0, length: 0 });
    const [whiteDots, setWhiteDots] = useState([]);
    const [isFalling, setIsFalling] = useState(false);
    const [hitStrength, setHitStrength] = useState(0); // 0 to MAX_DISTANCE
    const [strikerVelocity, setStrikerVelocity] = useState({ x: 0, y: 0 }); // Striker velocity
    const hitStrengthRef = useRef(0);
    const hitArrowRef = useRef({ angle: 0, length: 0 });
    const coinsRef = useRef(coins);
    const strikerVelocityRef = useRef({ x: 0, y: 0 });


    const HOLES = [
        { x: 15, y: 15, radius: 14 },  // Top-left corner
        { x: 285, y: 15, radius: 14 }, // Top-right corner
        { x: 15, y: 285, radius: 14 }, // Bottom-left corner
        { x: 285, y: 285, radius: 14 } // Bottom-right corner
    ];
    

    
        
    const handleStrikerTouchStart = (event) => {
        event.preventDefault();
        if (strikerVelocity.x !== 0 || strikerVelocity.y !== 0) return; // Prevent interaction during motion
        setIsTouchingStriker(true);
        setDragDots([]);
        setIsControllerActive(false);
    };
    
    

    const MAX_DISTANCE = 60; // Define maximum distance for circle, arrow, and dots

    const handleStrikerTouchMove = (event) => {
        event.preventDefault();
        if (!isTouchingStriker) return;
    
        const board = document.querySelector(".carrom-board");
        const boardRect = board.getBoundingClientRect();
    
        const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
        const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
    
        const relativeX = clientX - boardRect.left;
        const relativeY = clientY - boardRect.top;
    
        const dx = relativeX - strikerPosition.x;
        const dy = relativeY - strikerPosition.y;
        const distance = Math.min(Math.sqrt(dx * dx + dy * dy), MAX_DISTANCE);
    
        // Calculate strength and store it in the ref
        const calculatedStrength = distance < 10 ? 0 : (distance / MAX_DISTANCE) * 160;
        hitStrengthRef.current = calculatedStrength;

    
        const cappedDx = (dx / Math.sqrt(dx * dx + dy * dy)) * distance;
        const cappedDy = (dy / Math.sqrt(dx * dx + dy * dy)) * distance;
    
        const newDarkDots = [];
        const steps = Math.round(distance / 10);
        for (let i = 1; i <= steps; i++) {
            newDarkDots.push({
                x: strikerPosition.x + (cappedDx / steps) * i,
                y: strikerPosition.y + (cappedDy / steps) * i,
            });
        }
        setDragDots(newDarkDots);
    
        const newScale = distance / 11;
        setCircleScale(newScale);
    
        const arrowAngle = Math.atan2(-cappedDy, -cappedDx) * (180 / Math.PI);
        const arrowLength = Math.min(newScale * 11, distance);
    
        setHitArrow({
            angle: arrowAngle,
            length: arrowLength,
        });
        hitArrowRef.current = { angle: arrowAngle, length: arrowLength };
    
        const maxWhiteDots = 5;
        const whiteDotsSpacing = 10;
        const whiteDots = [];
        for (let i = 1; i <= maxWhiteDots; i++) {
            const arrowX = strikerPosition.x + (-cappedDx / distance) * arrowLength;
            const arrowY = strikerPosition.y + (-cappedDy / distance) * arrowLength;
            whiteDots.push({
                x: arrowX + (-cappedDx / distance) * whiteDotsSpacing * i,
                y: arrowY + (-cappedDy / distance) * whiteDotsSpacing * i,
                opacity: 1 - i * 0.2,
            });
        }
        setWhiteDots(whiteDots);
    };
    
    
    
    
    
    
    
    
    
    
    const handleStrikerTouchEnd = () => {
        
        const strength = hitStrengthRef.current; // Access the latest strength
        const { angle } = hitArrowRef.current; // Access the latest angle from the ref
   
    
        if (strength > 0) {
            const angleInRadians = angle * (Math.PI / 180);
            const speed = strength / 10;
    
            const velocityX = Math.cos(angleInRadians) * speed;
            const velocityY = Math.sin(angleInRadians) * speed;
    
    
            // Update the ref for smooth animations
            strikerVelocityRef.current = { x: velocityX, y: velocityY };
    
            // Start movement
            startStrikerMovement();
        } 
    
        setIsTouchingStriker(false);
        setDragDots([]);
        setCircleScale(1);
        setHitStrength(0);
        if (strength > 0 ){
            setIsControllerActive(false);
        } else {
            setIsControllerActive(true);
        }
        
    };
    
    
    
    
    
    



    const SENSITIVITY = 4;

const startStrikerMovement = () => {
    const updatePosition = () => {
       // if (isFallingRef.current) return; // Stop movement if the striker is falling into a hole

        const pocketedCoins = [];
        // Update striker position and velocity
        setStrikerPosition((prev) => {
            const velocity = strikerVelocityRef.current;

            let newX = prev.x + velocity.x;
            let newY = prev.y + velocity.y;

            let newVelocityX = velocity.x;
            let newVelocityY = velocity.y;

            // Wall collision logic for striker
            if (newX - STRIKER_RADIUS <= 0) {
                newX = STRIKER_RADIUS;
                newVelocityX = -newVelocityX * 0.8;
            }
            if (newX + STRIKER_RADIUS >= 300) {
                newX = 300 - STRIKER_RADIUS;
                newVelocityX = -newVelocityX * 0.8;
            }
            if (newY - STRIKER_RADIUS <= 0) {
                newY = STRIKER_RADIUS;
                newVelocityY = -newVelocityY * 0.8;
            }
            if (newY + STRIKER_RADIUS >= 300) {
                newY = 300 - STRIKER_RADIUS;
                newVelocityY = -newVelocityY * 0.8;
            }

            // Apply friction to the striker
            const friction = 0.98;
            newVelocityX *= friction;
            newVelocityY *= friction;

            // Stop if velocity is negligible
            if (Math.abs(newVelocityX) < 0.01) newVelocityX = 0;
            if (Math.abs(newVelocityY) < 0.01) newVelocityY = 0;

            // Update striker velocity reference
            strikerVelocityRef.current = { x: newVelocityX, y: newVelocityY };

            // Check for striker-coin collisions
            checkStrikerCoinCollision(newX, newY);

            // Check if striker is falling into a hole
            for (const hole of HOLES) {
                const dx = newX - hole.x;
                const dy = newY - hole.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= hole.radius - SENSITIVITY) {
                    handleStrikerFallIntoHole(hole);
                    return { x: hole.x, y: hole.y }; // Place striker at the hole's center
                }
            }

            return { x: newX, y: newY };
        });

        // Resolve coin-to-coin collisions
        resolveCoinCollisions();

        // Update coin positions and check for hole collisions
        coinsRef.current = coinsRef.current.map((coin) => {
            let newX = coin.x + coin.velocity.x;
            let newY = coin.y + coin.velocity.y;

            let newVelocityX = coin.velocity.x;
            let newVelocityY = coin.velocity.y;

            // Wall collisions for coins
            if (newX - COIN_RADIUS <= 0) {
                newX = COIN_RADIUS;
                newVelocityX *= -1;
            }
            if (newX + COIN_RADIUS >= 300) {
                newX = 300 - COIN_RADIUS;
                newVelocityX *= -1;
            }
            if (newY - COIN_RADIUS <= 0) {
                newY = COIN_RADIUS;
                newVelocityY *= -1;
            }
            if (newY + COIN_RADIUS >= 300) {
                newY = 300 - COIN_RADIUS;
                newVelocityY *= -1;
            }

            // Apply friction to coins
            const friction = 0.98;
            newVelocityX *= friction;
            newVelocityY *= friction;

            // Stop if velocity is negligible
            if (Math.abs(newVelocityX) < 0.01) newVelocityX = 0;
            if (Math.abs(newVelocityY) < 0.01) newVelocityY = 0;

            // Check if coin is falling into a hole
            for (const hole of HOLES) {
                const dx = newX - hole.x;
                const dy = newY - hole.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance <= hole.radius - SENSITIVITY) {
                    pocketedCoins.push({ coin, hole });
                   
                    console.log(`Coin ${coin.id} fell into the hole!`);
                
                    const coinType = coin.type;
                    const currentPlayerId = game.turn; // Current player
                    const opponentId = game.userIds.find(id => id !== currentPlayerId);
                    let ownerId = null;
                
                    if (coinType === 'black' || coinType === 'white') {
                        // Assign ownership of the coin based on color
                        ownerId = game.playerColors[currentPlayerId] === coinType ? currentPlayerId : opponentId;
                
                        if (ownerId) {
                            // Update pocketed pieces for the owner
                            game.pocketedPieces[ownerId] = [
                                ...(game.pocketedPieces[ownerId] || []),
                                coinType,
                            ];
                        }
                    } else if (coinType === 'queen') {
                        // Handle queen pocketing
                        game.queenPocketed = true;
                
                        // Assign it to the current player visually
                        ownerId = currentPlayerId;
                
                        // Update pocketed pieces for the queen
                        game.pocketedPieces[ownerId] = [
                            ...(game.pocketedPieces[ownerId] || []),
                            'queen',
                        ];
                        console.log(`Player ${ownerId} pocketed the queen coin!`);
                    }
           
                
                    if (pocketedCoins.length > 0) {
                        handlePocketedCoins(pocketedCoins, game);
                    }
                    // Remove the coin from the board
                    return null;
                }
                
                
                
            }

            return { ...coin, x: newX, y: newY, velocity: { x: newVelocityX, y: newVelocityY } };
        }).filter((coin) => coin !== null); // Remove coins that fell into holes

        // Sync coins state to trigger re-render
        setCoins([...coinsRef.current]);

        // Continue animation if velocity is non-zero
        if (
            Math.abs(strikerVelocityRef.current.x) > 0 ||
            Math.abs(strikerVelocityRef.current.y) > 0 ||
            coinsRef.current.some(
                (coin) => Math.abs(coin.velocity.x) > 0 || Math.abs(coin.velocity.y) > 0
            )
        ) {
            requestAnimationFrame(updatePosition);
        }
    };

    updatePosition(); // Start the loop
};

    
    
const triggerCoinPocketAnimation = (coin, hole, destinationElement, onComplete) => {
    const coinElement = document.querySelector(`#coin-${coin.id}`); // Find the DOM element for the coin
    if (!coinElement) {
        console.error(`Coin element not found for coin ID: ${coin.id}`);
        return;
    }

    const board = document.querySelector(".carrom-board"); // Reference to the board
    const boardRect = board.getBoundingClientRect();
    const coinRect = coinElement.getBoundingClientRect();

    // Clone the coin element for animation
    const clonedCoin = coinElement.cloneNode(true);
    clonedCoin.id = `animated-coin-${coin.id}`; // Assign a unique ID for the clone
    board.appendChild(clonedCoin);



    // Position the cloned coin at the center of the hole
    const holeCenterX = hole.x - coinRect.width / 2;
    const holeCenterY = hole.y - coinRect.height / 2;

    Object.assign(clonedCoin.style, {
        position: "absolute",
        zIndex: "100", // Ensure it's above other elements
        pointerEvents: "none", // Prevent interaction
        top: `${holeCenterY}px`,
        left: `${holeCenterX}px`,
        width: `${coinRect.width}px`,
        height: `${coinRect.height}px`,
    });

     // Add the falling animation class
     clonedCoin.classList.add("carrom-CoinFallingToHoll");

    // Wait 3 seconds before animating to the destination
    setTimeout(() => {
        const destinationRect = destinationElement.getBoundingClientRect();
        const dx = destinationRect.left - boardRect.left - holeCenterX;
        const dy = destinationRect.top - boardRect.top - holeCenterY;

        clonedCoin.classList.add("carrom-CoinMovingFromHoll");
        clonedCoin.style.transition = "transform 0.6s ease, top 0.6s ease, left 0.6s ease";
        clonedCoin.style.top = `${holeCenterY + dy}px`;
        clonedCoin.style.left = `${holeCenterX + dx}px`;

        // Clean up after animation
        clonedCoin.addEventListener(
            "transitionend",
            () => {
                clonedCoin.remove(); // Remove the cloned coin
                if (onComplete) onComplete(); // Notify animation completion
            },
            { once: true }
        );
    }, 3000); // 3-second delay
};






function handlePocketedCoins(pocketedCoins, game) {
    const playerCoins = [];
    const opponentCoins = [];
    let queenCoin = null;

    // Separate coins into categories
    pocketedCoins.forEach(({ coin, hole }) => {
        if (coin.type === "queen") {
            queenCoin = { coin, hole };
        } else if (coin.type === game.playerColors[game.turn]) {
            playerCoins.push({ coin, hole });
        } else {
            opponentCoins.push({ coin, hole });
        }
    });

    // Get destination sections
    const playerCoinsContainer = document.querySelector(".carrom-player-coins");
    const opponentCoinsContainer = document.querySelector(".carrom-opponent-coins");

    const playerSection = game.pocketedPieces[user._id]?.includes('queen')
        ? playerCoinsContainer.querySelector(".carrom-coin-icon:nth-child(2)") // Queen is always rendered first if present
        : playerCoinsContainer.querySelector(".carrom-coin-icon"); // Player's coin is rendered second if queen is present

    const opponentSection = opponentCoinsContainer.querySelector(".carrom-opponent-coin-icon");

    // Animate coins in order
    animateCoins(playerCoins, playerSection)
        .then(() => animateCoins(opponentCoins, opponentSection))
        .then(() => {
            if (queenCoin) {
                const queenDestination = playerCoinsContainer.querySelector(".carrom-coin-icon");
                triggerCoinPocketAnimation(queenCoin.coin, queenCoin.hole, queenDestination, () => {});
            }
        });
}




const animateCoins = async (coins, destination) => {
    for (const { coin, hole } of coins) { // Destructure coin and hole directly
        console.log("Animating coin:", coin, "to destination:", destination);

        await new Promise((resolve) =>
            triggerCoinPocketAnimation(coin, hole, destination, resolve)
        );
    }
};

    

  
    
    
    
    
    
   
    const checkStrikerCoinCollision = (newX, newY) => {
        const collisionCooldownFrames = 1; // Number of frames to skip post-collision
        let lastCollisionFrame = -Infinity; // Track the frame of the last collision
    
        const updatedCoins = coinsRef.current.map((coin) => {
            const dx = coin.x - newX;
            const dy = coin.y - newY;
            const distance = Math.sqrt(dx * dx + dy * dy);
    
            if (distance < STRIKER_RADIUS + COIN_RADIUS) {
                const currentFrame = performance.now();
    
                // Avoid repeated collisions within cooldown period
                if (currentFrame - lastCollisionFrame < collisionCooldownFrames) {
                    return coin;
                }
                lastCollisionFrame = currentFrame;
    
                // Collision detected
                let angle = Math.atan2(dy, dx);
           
    
                const normal = { x: Math.cos(angle), y: Math.sin(angle) };
                const tangent = { x: -normal.y, y: normal.x };
    
                // Decompose velocities
                const strikerNormalSpeed =
                    strikerVelocityRef.current.x * normal.x +
                    strikerVelocityRef.current.y * normal.y;
                const strikerTangentSpeed =
                    strikerVelocityRef.current.x * tangent.x +
                    strikerVelocityRef.current.y * tangent.y;
    
                const coinNormalSpeed = coin.velocity.x * normal.x + coin.velocity.y * normal.y;
                const coinTangentSpeed = coin.velocity.x * tangent.x + coin.velocity.y * tangent.y;
    
                // Energy loss factor
                const energyLossFactor = 0.8;
    
                // Exchange normal components
                const strikerNormalSpeedFinal = coinNormalSpeed * energyLossFactor;
                const coinNormalSpeedFinal = strikerNormalSpeed * energyLossFactor;
    
                // Reconstruct velocities
                const newStrikerVelocity = {
                    x: strikerNormalSpeedFinal * normal.x + strikerTangentSpeed * tangent.x,
                    y: strikerNormalSpeedFinal * normal.y + strikerTangentSpeed * tangent.y,
                };
    
                const newCoinVelocity = {
                    x: coinNormalSpeedFinal * normal.x + coinTangentSpeed * tangent.x,
                    y: coinNormalSpeedFinal * normal.y + coinTangentSpeed * tangent.y,
                };
    
          
    
                // Resolve overlap
                const overlap = STRIKER_RADIUS + COIN_RADIUS - distance;
                const overlapResolutionFactor = 1.2; // Increased resolution factor for direct hits
                coin.x += normal.x * overlap * overlapResolutionFactor;
                coin.y += normal.y * overlap * overlapResolutionFactor;
                newX -= normal.x * overlap * overlapResolutionFactor;
                newY -= normal.y * overlap * overlapResolutionFactor;
    
                // Add angular velocity for direct hits
                const angleAdjustment = 0.05; // Small adjustment to prevent straight line locking
                if (Math.abs(dx) < 0.5 && Math.abs(dy) < 0.5) {
                    angle += angleAdjustment;
                }
    
          
                // Minimum velocity threshold
                const velocityThreshold = 0.1;
                if (Math.abs(newStrikerVelocity.x) < velocityThreshold) newStrikerVelocity.x = 0;
                if (Math.abs(newStrikerVelocity.y) < velocityThreshold) newStrikerVelocity.y = 0;
                if (Math.abs(newCoinVelocity.x) < velocityThreshold) newCoinVelocity.x = 0;
                if (Math.abs(newCoinVelocity.y) < velocityThreshold) newCoinVelocity.y = 0;
    
                // Update velocities
                strikerVelocityRef.current = newStrikerVelocity;
    
                return { ...coin, velocity: newCoinVelocity };
            }
    
            return coin;
        });
    
        // Sync the updated coins back to the ref
        coinsRef.current = updatedCoins;
    
        // Trigger UI update
        setCoins([...updatedCoins]);
    };
    
    
    
    
    
    
    
    useEffect(() => {
        if (strikerVelocity.x !== 0 || strikerVelocity.y !== 0) {
            strikerVelocityRef.current = strikerVelocity;
        }
    }, [strikerVelocity]);
    
    
    
    const resolveCoinCollisions = () => {
        const updatedCoins = coinsRef.current;
    
        // Iterate over each pair of coins
        for (let i = 0; i < updatedCoins.length; i++) {
            const coinA = updatedCoins[i];
            for (let j = i + 1; j < updatedCoins.length; j++) {
                const coinB = updatedCoins[j];
    
                const dx = coinB.x - coinA.x;
                const dy = coinB.y - coinA.y;
                const distance = Math.sqrt(dx * dx + dy * dy);
    
                if (distance < COIN_RADIUS * 2) {
                    // Collision detected
                    const overlap = COIN_RADIUS * 2 - distance;
    
                    // Resolve overlap
                    const angle = Math.atan2(dy, dx);
                    const cosAngle = Math.cos(angle);
                    const sinAngle = Math.sin(angle);
    
                    const resolveFactor = overlap / 2;
                    coinA.x -= cosAngle * resolveFactor;
                    coinA.y -= sinAngle * resolveFactor;
                    coinB.x += cosAngle * resolveFactor;
                    coinB.y += sinAngle * resolveFactor;
    
                    // Calculate normal and tangent velocities
                    const normal = { x: cosAngle, y: sinAngle };
                    const tangent = { x: -sinAngle, y: cosAngle };
    
                    const vA_normal = coinA.velocity.x * normal.x + coinA.velocity.y * normal.y;
                    const vA_tangent = coinA.velocity.x * tangent.x + coinA.velocity.y * tangent.y;
    
                    const vB_normal = coinB.velocity.x * normal.x + coinB.velocity.y * normal.y;
                    const vB_tangent = coinB.velocity.x * tangent.x + coinB.velocity.y * tangent.y;
    
                    // Elastic collision formula (assuming equal mass)
                    const energyLossFactor = 0.98; // 98% energy retained
                    const vA_normal_final = vB_normal * energyLossFactor;
                    const vB_normal_final = vA_normal * energyLossFactor;
    
                    // Reconstruct velocities after collision
                    coinA.velocity = {
                        x: vA_normal_final * normal.x + vA_tangent * tangent.x,
                        y: vA_normal_final * normal.y + vA_tangent * tangent.y,
                    };
                    coinB.velocity = {
                        x: vB_normal_final * normal.x + vB_tangent * tangent.x,
                        y: vB_normal_final * normal.y + vB_tangent * tangent.y,
                    };
    
                    // Prevent coins from "sticking" together
                    const minSeparation = 0.8; // Small separation to avoid jittering
                    if (overlap < minSeparation) {
                        coinA.x -= cosAngle * minSeparation;
                        coinA.y -= sinAngle * minSeparation;
                        coinB.x += cosAngle * minSeparation;
                        coinB.y += sinAngle * minSeparation;
                    }
                }
            }
        }
    };
    
    

    
    


const isFallingRef = useRef(false);

const handleStrikerFallIntoHole = (hole) => {
    // Stop movement and set the falling state
    isFallingRef.current = true;
    strikerVelocityRef.current = { x: 0, y: 0 };

    setStrikerPosition({
        x: hole.x - STRIKER_SIZE / 2,
        y: hole.y - STRIKER_SIZE / 2,
    });

    setIsFalling(true);

    audioManager.playSound("fall-into-hole");

    setTimeout(() => {
        setIsFalling(false);
        isFallingRef.current = false; // Allow movement updates again
        setStrikerPosition({ x: 150, y: 256.5 }); // Reset position
        setIsControllerActive(true);
    }, 1000);
};

    
    
    

        

useEffect(() => {
    const handleMouseMove = (event) => {
        if (isDragging) handleDragMove(event);
        if (isTouchingStriker) handleStrikerTouchMove(event);
    };

    const handleMouseUp = () => {
        if (isDragging) handleDragEnd();
        if (isTouchingStriker) handleStrikerTouchEnd();
    };

    const preventDefaultTouch = (event) => {
        event.preventDefault(); // Prevent default behavior (e.g., page scroll, Telegram minimize)
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchmove", handleMouseMove, { passive: false });
    window.addEventListener("touchend", handleMouseUp, { passive: false });
    window.addEventListener("touchstart", preventDefaultTouch, { passive: false });

    return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("touchmove", handleMouseMove);
        window.removeEventListener("touchend", handleMouseUp);
        window.removeEventListener("touchstart", preventDefaultTouch);
    };
}, [isDragging, isTouchingStriker]);



    



    const renderPlayArea = () => {
        return (
            <div className="carrom-board-wrapper">
                <div className="carrom-board-container">
                    <div className="carrom-board-border">
                        <div className="carrom-board">
                            <div className="board-lines"></div>
    
                            {/* Render coins */}
                            {coinsRef.current.map((coin) => (
    <img
        key={coin.id}
        id={`coin-${coin.id}`} // Assign a unique ID for each coin
        src={`/carromAssets/${coin.type}-coin.png`}
        alt={`${coin.type} coin`}
        className="carrom-coin"
        style={{
            top: `${coin.y}px`,
            left: `${coin.x}px`,
            transform: 'translate(-50%, -50%)', // Center the coin
        }}
    />
))}

    
                            {/* Render striker animation */}
                        {isPlayerTurn && isControllerActive  && (
                            <div
                                className={`carrom-striker-animation ${
                                    isDragging ? "carrom-small" : ""
                                }`}
                                style={{
                                    top: `${strikerPosition.y}px`, // Center circle around striker
                                    left: `${strikerPosition.x}px`,
                                }}
                            >
                                <div className="carrom-arrow carrom-arrow-top"></div>
                                <div className="carrom-arrow carrom-arrow-bottom"></div>
                                <div className="carrom-arrow carrom-arrow-left"></div>
                                <div className="carrom-arrow carrom-arrow-right"></div>
                            </div>
                        )}


                        

                        {/* Render striker */}
                        <img
                            src="/carromAssets/striker.png"
                            alt="Striker"
                            className={`carrom-striker ${isFalling ? "falling" : ""}`}
                            style={{
                                top: `${strikerPosition.y}px`,
                                left: `${strikerPosition.x}px`,
                                transform: `translate(-50%, -50%)`
                            }}
                            onMouseDown={handleStrikerTouchStart}
                            onTouchStart={handleStrikerTouchStart}
                        />
                        {/* Hit Circle */}
{/* Hit Circle */}
{isTouchingStriker && (
    <div
        className="carrom-hit-circle"
        style={{
            top: `${strikerPosition.y}px`,
            left: `${strikerPosition.x}px`,
            width: `${22 * circleScale}px`, // Scale dynamically
            height: `${22 * circleScale}px`, // Scale dynamically
            transform: `translate(-50%, -50%)`,
        }}
    ></div>

    )}

  {/* Hit Arrow */}
{isTouchingStriker && (
    <div
        className="carrom-hit-arrow"
        style={{
            top: `${strikerPosition.y}px`,
            left: `${strikerPosition.x}px`,
            width: `${hitArrow.length}px`, // Arrow length
            height: `4px`, // Arrow thickness
            backgroundColor: "gold",
            transform: `translate(0, -50%) rotate(${hitArrow.angle}deg)`, // Proper rotation
            transformOrigin: "0% 50%", // Arrow starts at the striker center
        }}
    ></div>
)}

{/* Render White Dots */}
{isTouchingStriker &&
    whiteDots.map((dot, index) => (
        <div
            key={`carrom-white-dot-${index}`}
            className="white-dot"
            style={{
                position: "absolute",
                width: "8px",
                height: "8px",
                backgroundColor: "white",
                borderRadius: "50%",
                top: `${dot.y}px`,
                left: `${dot.x}px`,
                opacity: dot.opacity,
                transform: "translate(-50%, -50%)",
            }}
        ></div>
    ))}




                        {/* Render Drag Dots */}
{dragDots.map((dot, index) => (
    <div
        key={`dot-${index}`}
        className="drag-dot"
        style={{
            position: "absolute",
            width: "6px",
            height: "6px",
            backgroundColor: "black",
            borderRadius: "50%",
            top: `${dot.y}px`,
            left: `${dot.x}px`,
            transform: "translate(-50%, -50%)",
        }}
    ></div>
))}


                        
                    </div>
                </div>
            </div>

            
    
                  {/* Striker Controller */}
            <div
                className={`striker-controller ${isPlayerTurn && isControllerActive ? "active" : "inactive"}`}
            >
                <div
                    className="striker-handle"
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                    style={{ left: `${handlePosition}px` }}
                ></div>
            </div>
        </div>
        );
    };
    
    
    
    
    
    
    


    const renderPlayerArea = (game, user) => {
        if (!user) {
            return null; 
        }
    
        const playerPoints = game.totalGamePoints[user._id] || 0;
    
        return (
            <div className="carrom-player-area">
             
                <div className="carrom-player-avatar">
                    {renderAvatar(user.nickname, user.photoUrl)}
                </div>
    
      
                <div className="carrom-player-info">
                    <div className="carrom-name-and-flag">
                        <span className="carrom-player-name">{user.nickname}</span>
                        {user.country && (
                            <img
                                src={`/flags/${user.country.toLowerCase()}.svg`}
                                alt={`${user.country} flag`}
                                className="carrom-country-flag"
                            />
                        )}
                    </div>
                    <span className="carrom-player-score">Score: {user.score}</span>
                    <span className="carrom-player-betAmmount">Bet: ${game?.betAmount}</span>
                </div>
                {/* New Small Rectangle for Coins */}
                <div className="carrom-player-coins">
    {/* Render the red coin (queen) if pocketed */}
    {game.pocketedPieces[user._id]?.includes('queen') && (
        <div
            className="carrom-coin-icon"
            style={{
                backgroundImage: `url(/carromAssets/queen-coin.png)`,
            }}
        ></div>
    )}

    {/* Render the player's color coin */}
    <div
        className="carrom-coin-icon"
        style={{
            backgroundImage: `url(/carromAssets/${game.playerColors[user._id]}-coin.png)`,
        }}
    ></div>
    <div className="carrom-coin-count">
        {game.pocketedPieces[user._id]?.filter(coin => coin === game.playerColors[user._id]).length || 0}
    </div>
</div>
            </div>
        );
    };
    


    
    // Render cancel popup
    const renderPopup = () => {
        if (ShowCancelPopup) {
            return (
                <div className="carrom-popup">
                    <div className="carrom-popup-inner">
                        <h3>Game Canceled</h3>
                        <p>The game has been canceled. Your bet has been refunded.</p>
                        <button onClick={() => setShowCancelPopup(false)}>Close</button>
                    </div>
                </div>
            );
        }
    };

    // Main rendering logic
    if (!game) {
        return (
            <div className="carrom-container">
                <h2>Loading game...</h2>
            </div>
        );
    }








    


    
    return (
 
        <div className="carrom-container">
            {renderOpponentArea(game, user, opponents)}



            
            {renderPlayArea()}
          


            <div className="carrom-player-wrapper">
            {renderPlayerArea(game, user)}
            </div>


            {renderPopup()}
        
        </div>
    );
};

export default CarromGame;
