export const apiRequest = async (url, options = {}) => {

    options.credentials = 'include';

    if (!options.headers) {
        options.headers = {};
    }

    let response = await fetch(url, options);


    if (response.status === 401) {
        console.error('Unauthorized, possibly due to an expired token.');

        return response;
    }

    return response;
};

