import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiRequest } from '../api';
import './RegistrationForm.css'; 
import API_BASE_URL from '../apiConfig';

const RegistrationForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPassword, setShowPassword] = useState(false); 
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
    const [showPopup, setShowPopup] = useState(false); 
    const navigate = useNavigate();
    const location = useLocation(); 


    const escapeHTML = (input) => {
        return input
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };
    

    
    const searchParams = new URLSearchParams(location.search);
    const invitedBy = searchParams.get('invitedBy') || searchParams.get('ref') || searchParams.get('start');

    const validateForm = (username, email, password, confirmPassword) => {
        const newErrors = {};
    
        if (!username) newErrors.username = 'Username is required';
        if (!email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';
    
        if (!password) newErrors.password = 'Password is required';
        else if (password.length < 8) newErrors.password = 'Password must be at least 8 characters';
    
        if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    
        return newErrors;
    };
    

    const handleRegister = async (e) => {
        e.preventDefault();


const sanitizedUsername = escapeHTML(username);
const sanitizedEmail = escapeHTML(email);



const formErrors = validateForm(sanitizedUsername, sanitizedEmail, password, confirmPassword); // Leave password as is
    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }
        setIsProcessing(true);
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/register` , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: sanitizedUsername,
                    email: sanitizedEmail,
                    password, 
                    invitedBy
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setShowPopup(true); 
            } else {
                setMessage(data.message || 'Failed to register');
            }
        } catch (err) {
            setMessage('An error occurred');
            console.error('Error registering:', err);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleGoToLogin = () => {
        navigate('/login');
    };

    return (
        <div className="registration-container">
            <h2 className="registration-title">Register</h2>
            <form onSubmit={handleRegister} className="registration-form">
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="input-field"
                        required
                    />
                    {errors.username && <p className="error-text">{errors.username}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input-field"
                        required
                    />
                    <p className="email-description">
                        Your email is used for account verification, password recovery, and important notifications.
                    </p>
                    {errors.email && <p className="error-text">{errors.email}</p>}
                </div>
                <div className="form-group password-group">
                    <label htmlFor="password">Password:</label>
                    <div className="password-field-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-field"
                            required
                        />
                        <img
                            src="/icons/eye-icon.svg"
                            alt="Toggle password visibility"
                            className={`password-toggle-icon ${showPassword ? 'show' : ''}`}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    <p className="password-guideline">Password must be at least 8 characters long.</p>
                    {errors.password && <p className="error-text">{errors.password}</p>}
                </div>
                <div className="form-group password-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <div className="password-field-container">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="input-field"
                            required
                        />
                        <img
                            src="/icons/eye-icon.svg"
                            alt="Toggle confirm password visibility"
                            className={`password-toggle-icon ${showConfirmPassword ? 'show' : ''}`}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                    </div>
                    {errors.confirmPassword && <p className="error-text">{errors.confirmPassword}</p>}
                </div>
                <button 
                    type="submit" 
                    className={`register-button ${isProcessing ? 'disabled' : ''}`} 
                    disabled={isProcessing}
                >
                    {isProcessing ? (
                        <>
                            <span className="spinner"> </span>   Processing...
                        </>
                    ) : "Register"} 
                </button>
            </form>
            {message && <p className="error-message">{message}</p>}

            
            {showPopup && (
                <div className="registration-popup-overlay">
                    <div className="registration-popup">
                        <h3>Congratulations!</h3>
                        <p>You have successfully registered.</p>
                        <button className="popup-button" onClick={handleGoToLogin}>Go to Login</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegistrationForm;
